/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.section > .sectionFooter {
  margin-top: 60px;
}
.section > .backButton {
  transition: all 0.15s;
}
.section > .backButton > .text {
  display: inline-block;
  color: #9158a3;
  margin-left: 10px;
}
.section > .backButton:hover {
  transform: scale(1.1);
}
.section,
.section .article > .maxArticleWrapper {
  overflow: visible;
  position: relative;
  margin: 0 20px 60px;
  /* Always ensure there is a margin of at least 20px */
}
@media (min-width: 1124px) {
  .section,
  .section .article > .maxArticleWrapper {
    margin: 0 auto 60px;
    width: 1084px;
  }
}
.maxWidthWrapper > div > div > .title,
.section > .title,
.section > div > .title,
.section > div > div > .title {
  font-size: 3em;
  font-weight: 600;
  color: #745a9e;
  font-family: 'Zona Pro', 'Verdana';
  text-align: center;
  margin-top: 60px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .maxWidthWrapper > div > div > .title,
  .section > .title,
  .section > div > .title,
  .section > div > div > .title {
    font-size: 2.2em;
    margin-left: 1rem;
  }
}
@media (max-width: 991px) {
  .maxWidthWrapper > div > div > .title,
  .section > .title,
  .section > div > .title,
  .section > div > div > .title {
    margin-left: 10px;
  }
}
.maxWidthWrapper > div > div > .subtitle,
.section > .subtitle,
.section > div > .subtitle,
.section > div > div > .subtitle {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 4rem;
  color: #745a9e;
  font-size: 24px;
  text-align: center;
}
@media (max-width: 991px) {
  .maxWidthWrapper > div > div > .subtitle,
  .section > .subtitle,
  .section > div > .subtitle,
  .section > div > div > .subtitle {
    font-size: 1.6rem;
  }
}
