/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.videoImage > .overlay {
  background: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
  transition: all 0.15s;
  position: relative;
}
.videoImage > .overlay > .titleBar {
  width: 100%;
  background: #9158a3;
  color: #fff;
  font-family: 'Zona Pro', 'Verdana';
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  padding: 10px;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
}
.videoImage > .overlay > .buttonWrapper {
  border-radius: 50px;
  border: 2px solid #fff;
  color: #fff;
  width: 200px;
  height: 45px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -23px;
  transition: all 0.15s;
  box-sizing: border-box;
}
.videoImage > .overlay > .buttonWrapper > .text {
  font-family: 'Zona Pro', 'Verdana';
  font-size: 18px;
  position: absolute;
  font-weight: normal;
  line-height: 1;
  left: 62px;
  top: 10px;
}
.videoImage > .overlay > .buttonWrapper > img {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 2px;
  left: 4px;
}
.videoImage:hover > .overlay {
  background: rgba(0, 0, 0, 0);
}
.videoImage:hover > .overlay > .sprite {
  transform: scale(1.2);
}
